import { Fragment, useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  getFormatDate,
  getKoreanDayByDayNumber,
  getSessionValue,
  getTodayYearAndMonth,
} from "../../../function/common";
import useRemoveBodyStyle from "../../../hooks/common/useRemoveBodyStyle";
import useDetailSalesInfo from "../../../hooks/paymentinfo/useDetailSalesInfo";
import useSaleExcelDownload from "../../../hooks/paymentinfo/useSaleExcelDownload";
import AdminHeader from "../../../pages/UserMain/components/AdminHeader";
import LoadingPortal from "../../../portals/LoadingPortal";
import PaymentDay from "../../common/PaymentDay";
import PaymentTimes from "../../common/PaymentTimes";
import YearDatepicker from "../../common/YearDatepicker";
import LoadingModal from "../../loadingmodal/LoadingModal";
import { useAppDispatch, useAppSelector } from "../../../store";
import { fetchTaxBillInfo } from "../../../store/modules/taxBillSlice";
import PageNation from "../../common/Pagenation";
import AccountingGNB from "../../../pages/accounting/header/AccountingGNB";
import { apiClient } from "../../../api/apiClient";
function SelectedYear() {
  const input = document.querySelector(".yearTarget");

  const checkInputSelector = document.querySelector(
    "input[name=yearItem]:checked + label"
  );

  if (!checkInputSelector) {
    return;
  }
  const CheckInput = checkInputSelector.innerHTML.trim();

  input.value = CheckInput;
}

export default function TaxBillInfo() {
  useRemoveBodyStyle();

  const pageChange = (props) => {
    // debugger;

    dispatch(fetchTaxBillInfo(props));
  };

  const dispatch = useAppDispatch();

  const responseData = useAppSelector((state) => {
    return state.taxBillReducer;
  });

  const totalPage = useAppSelector((state) => {
    return state.taxBillReducer.totalPages;
  });

  const [isExcelLoading, setIsExcelLoading] = useState(false);

  const modalRef = useRef();

  const tableRef = useRef();
  // const salesInfos = null;
  // const { salesInfos, refetch, isLoading } = useDetailSalesInfo(

  //   paymentDate,
  //   paymentTime,
  //   paymentMonth,
  //   setShowingTime,
  //   setShowingDate,
  //   setShowingMonth,

  //   modalRef
  // );
  // const { excelMutate, isExcelLoading } = useSaleExcelDownload({
  //   status: showingStatus,
  //   paymentDate: showingDate,
  //   paymentTime: showingTime,
  //   paymentMonth: showingMonth,
  // });

  const getFileName = (response) => {
    const disposition = response.headers["content-disposition"];

    const fileName = decodeURI(
      disposition
        .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
        .replace(/['"]/g, "")
    );
    return fileName;
  };

  const requestTaxBillDownLoad = (taxInvoiceId) => {
    setIsExcelLoading(true);
    apiClient({
      url: `/tax-invoices/` + taxInvoiceId + `/excel`,
      method: "post",
      responseType: "arraybuffer",
    })
      .then((response) => {
        const blob = new Blob([response.data]);
        const fileUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileUrl;
        link.style.display = "none";
        link.download = getFileName(response);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setIsExcelLoading(false);
      })
      .catch(({ response }) => {
        // console.log(error)
        setIsExcelLoading(false);
        alert(response.data.message);
      });
  };

  if (!responseData) {
    return (
      <LoadingPortal>
        <LoadingModal />
      </LoadingPortal>
    );
  }

  const taxBillDownLoad = (taxInvoiceId) => {
    return (
      <button
        type="button"
        className="btn-clear PrdTbl-down-center"
        onClick={() => requestTaxBillDownLoad(taxInvoiceId)}
      >
        <img
          className="img-fluid2"
          alt=""
          src="https://www.flexdaycdn.net/public/images/ticket/down_icon.png"
        />
        <span>다운로드</span>
      </button>
    );
  };

  const mapedTaxBillInfos = responseData.content.map((response, index) => {
    const {
      id,
      issuedDate,
      startOfReferenceDate,
      endOfReferenceDate,
      supplyOfFee,
      vatOfFee,
      fee,
    } = response;
    return (
      <tr key={index}>
        <td data-txt="No.">{(id % responseData?.numberOfElements) + 1}</td>
        <td data-txt="세금계산서 발행일자">{issuedDate}</td>
        <td data-txt="세금계산서 발행 기준일자">
          {startOfReferenceDate} ~ {endOfReferenceDate}
        </td>
        <td data-txt="공급가액">{supplyOfFee}</td>
        <td data-txt="부가세액">{vatOfFee}</td>
        <td data-txt="부가세액">{fee}</td>
        <td data-txt="세금계산서 다운로드">{taxBillDownLoad(id)}</td>
      </tr>
    );
  });

  // const mapedSaleInfos = salesInfos.sales.map((saleInfo, index) => {
  //   const { amount, menuName, count } = salesInfos.sum;
  //   const {
  //     amount: productAmount,
  //     menuName: productName,
  //     count: productCount,
  //   } = saleInfo;

  //   if (!index) {
  //     return (
  //       <Fragment key={index}>
  //         <tr className="total">
  //           <td data-txt="메뉴">{menuName}</td>
  //           <td data-txt="사용 현황">{count}</td>
  //           <td data-txt="결제 금액">{amount.toLocaleString()}원</td>
  //         </tr>
  //         <tr>
  //           <td data-txt="메뉴">{productName}</td>
  //           <td data-txt="사용 현황">{productCount}</td>
  //           <td data-txt="결제 금액">{productAmount.toLocaleString()}원</td>
  //         </tr>
  //       </Fragment>
  //     );
  //   }

  //   return (
  //     <tr key={index}>
  //       <td data-txt="메뉴">{productName}</td>
  //       <td data-txt="사용 현황">{productCount}</td>
  //       <td data-txt="결제 금액">{productAmount.toLocaleString()}원</td>
  //     </tr>
  //   );
  // });

  return (
    <>
      <AccountingGNB />
      <div className="demo mw-100">
        <div className="TPrdAdminPage">
          <div className="container-fluid g-0">
            <div className="PayCateTab">
              <div className="SalesList">
                <div className="PrdAdmin-Bottom pb-5 px-4-5">
                  {/* <div className="PrdTbl-excelbox pc">
                    <button
                      type="button"
                      className="btn-clear PrdTbl-down"
                      onClick={handleExcelDownloadClick}
                    >
                      <span>엑셀 다운로드</span>
                      <img
                        className="img-fluid"
                        alt=""
                        src="https://www.flexdaycdn.net/public/images/ticket/down_icon.png"
                      />
                    </button>
                  </div> */}

                  <div className="PrdTbl-wrap">
                    <table className="PrdTbl table" ref={tableRef}>
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>세금계산서 발행 일자</th>
                          <th>세금계산서 발행 기준일자</th>
                          <th>공급가액</th>
                          <th>부가가액</th>
                          <th>합계 금액</th>
                          <th>세금계산서 상세내역</th>
                        </tr>
                      </thead>
                      <tbody>{mapedTaxBillInfos}</tbody>
                      {/* <tbody>{mapedSaleInfos}</tbody> */}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PageNation totalPage={totalPage} callBack={pageChange} />
      {isExcelLoading && (
        <LoadingPortal>
          <LoadingModal type="black" />
        </LoadingPortal>
      )}
    </>
  );
}
