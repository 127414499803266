import { useState, useEffect, useRef } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import {
  getFormatDate,
  getKoreanDayByDayNumber,
  getSessionValue,
  getTodayYearAndMonth,
  isDefaultStore,
} from "../../../function/common";

import useRemoveBodyStyle from "../../../hooks/common/useRemoveBodyStyle";

import useDetailPaymentOrderInfo from "../../../hooks/paymentinfo/useDetailPaymentOrderInfo";
import useOrderCancel from "../../../hooks/paymentinfo/useOrderCancel";
import PaymentDay from "../../common/PaymentDay";
import PaymentTimes from "../../common/PaymentTimes";
import Pagenation from "../pagenation/Pagenation";

import YearDatepicker from "../../common/YearDatepicker";
import LoadingPortal from "../../../portals/LoadingPortal";
import LoadingModal from "../../loadingmodal/LoadingModal";
import { useUsedQR } from "../../../hooks/paymentinfo/useUsedQR";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { fetchPaymentOrderInfo } from "../../../store/modules/paymentOrderSlice";
import PaymentOrderDetailParam from "../../../param/PaymentOrderDetailParam";
import {
  DATE_RANGE_TYPE,
  SMART_ORDER_STATUS,
} from "../../constants/CommonConstants";
import { PAYTYPE_STATUS } from "../../constants/CommonConstants";
import usePaymentOrderExcelDownload from "../../../hooks/paymentinfo/usePaymentOrderExcelDownload";
import AccountingGNB from "../../../pages/accounting/header/AccountingGNB";

const CANCELTYPE_STATUS = {
  ALL: "",
  YES: "true",
  NO: "false",
};

const USEDTYPE_STATUS = {
  ALL: "",
  YES: "true",
  NO: "false",
};

window.addEventListener("load", () => {
  SelectedYear();
});

function SelectedYear() {
  const input = document.querySelector(".yearTarget");

  const checkInputSelector = document.querySelector(
    "input[name=yearItem]:checked + label"
  );

  if (!checkInputSelector) {
    return;
  }

  const CheckInput = checkInputSelector.innerHTML.trim();

  input.value = CheckInput;
}

export default function PaymentOrderDetailInfo() {
  const navigate = useNavigate();

  const storeType = useAppSelector((state) => state.storeReducer.storeType);
  useEffect(() => {
    if (storeType === null) return;
    if (storeType !== "SMART_ORDER") {
      navigate("/paymentInfo");
    }
  }, [storeType]);

  useRemoveBodyStyle();

  const [showingName, setShowingName] = useState("");
  const [showingPhone, setShowingPhone] = useState("");
  const [name, setName] = useState("");
  const [showingStatus, setShowingStatus] = useState(
    getSessionValue("status") || DATE_RANGE_TYPE.TODAY
  );
  const [status, setStatus] = useState(
    getSessionValue("status") || DATE_RANGE_TYPE.TODAY
  );
  const modalRef = useRef();
  const [todayInfoDate, setTodayInfoDate] = useState({
    totalDate: getFormatDate(),
    day: new Date().getDay(),
  });
  const [showingMonth, setShowingMonth] = useState(
    getSessionValue("month") || getTodayYearAndMonth()
  );
  const [paymentMonth, setPaymentMonth] = useState(
    getSessionValue("month") || getTodayYearAndMonth()
  );
  const [showingDate, setShowingDate] = useState({
    startDate: getSessionValue("startDate") || getFormatDate(),
    endDate: getSessionValue("endDate") || getFormatDate(),
  });
  const [paymentDate, setPaymentDate] = useState({
    startDate: getSessionValue("startDate") || getFormatDate(),
    endDate: getSessionValue("endDate") || getFormatDate(),
  });
  const [showingTime, setShowingTime] = useState({
    start: getSessionValue("startTime") || "00:00",
    end: getSessionValue("endTime") || "23:00",
  });
  const [paymentTime, setPaymentTime] = useState({
    startTime: getSessionValue("startTime") || "00:00",
    endTime: getSessionValue("endTime") || "23:00",
  });

  const payModalRef = useRef();
  const [cancelType, setCancelType] = useState("");
  const cancelModalRef = useRef();
  const [isCancelShowingModal, setCancelShowingModal] = useState(false);
  const [orderNumber, setOrderNumber] = useState();
  const [isUsedShowingModal, setUsedShowingModal] = useState(false);
  const [qrCode, setQRCode] = useState();
  const tableRef = useRef();
  const [searchType, setSearchType] = useState("NAME");
  const [usedType, setUsedType] = useState("");
  const [phone, setPhone] = useState();
  const [smartOrderStatus, setSmartOrderStatus] = useState("");
  const usedModalRef = useRef();
  const [page, setPage] = useState(getSessionValue("page") || 0);
  const [payType, setPayType] = useState("");

  /**
   * actor : SJ.CHOI
   * 추 후 리팩토링 대상
   * ============================================================================
   */

  // const [searchDate, setSearchDate] = useState({
  //     startDate: getSessionValue("startDate") || getFormatDate(),
  //     endDate: getSessionValue("endDate") || getFormatDate(),
  // });

  // const [searchTime, setSearchTime] = useState({
  //     startTime: getSessionValue("startTime") || "00:00",
  //     endTime: getSessionValue("endTime") || "23:00",
  // });

  // const [searchParam, setSearchParam] = useState(
  // new PaymentOrderDetailParam('2023-05-01', searchTime.startTime, '2023-06-01', searchTime.endTime,  DATE_RANGE_TYPE.MONTH, page)
  // new PaymentOrderDetailParam(searchDate.startDate, searchTime.startTime, searchDate.endDate, searchTime.endTime,  DATE_RANGE_TYPE.TODAY, page)
  // );

  // const dispatch = useAppDispatch();

  // useEffect(() => {
  //     dispatch(fetchPaymentOrderInfo(
  //         searchParam
  //         ));
  // }, [dispatch]);

  // const responseData = useAppSelector((state) => { return state.paymentOrderReducer});
  // const isLoading = useAppSelector((state) => {return state.paymentOrderReducer.isLoading});

  /**
   * ============================================================================
   *
   */

  const { paymentInfos, refetch, isLoading } = useDetailPaymentOrderInfo(
    showingName,
    setShowingName,
    showingPhone,
    setShowingPhone,
    name,
    phone,
    status,
    paymentDate,
    paymentTime,
    paymentMonth,
    showingDate,
    showingTime,
    showingMonth,
    setShowingTime,
    setShowingDate,
    setShowingMonth,
    setShowingStatus,
    modalRef,
    page,
    payType,
    payModalRef,
    cancelType,
    cancelModalRef,
    usedType,
    usedModalRef,
    searchType,
    smartOrderStatus
  );

  const { usedMutate, isUsedLoading } = useUsedQR({
    setUsedShowingModal,
    refetch,
  });
  const { mutate, isRemoveLoading } = useOrderCancel(
    setCancelShowingModal,
    refetch
  );
  const { excelMutate, isExcelLoading } = usePaymentOrderExcelDownload({
    status: showingStatus,
    paymentDate: showingDate,
    paymentTime: showingTime,
    paymentMonth: showingMonth,
    smartOrderStatus: smartOrderStatus,
  });

  useEffect(() => {
    return () => {
      sessionStorage.clear();
    };
  }, []);

  useEffect(() => {
    setName("");
    setPhone("");
  }, [searchType]);
  // console.log(paymentInfos);
  if (!paymentInfos) {
    return (
      <LoadingPortal>
        <LoadingModal />
      </LoadingPortal>
    );
  }

  // if(!responseData){
  //     return(
  //         <LoadingPortal>
  //             <LoadingModal />
  //         </LoadingPortal>
  //     )
  // }

  const handleSubSearchBtnClick = () => {
    if (isLoading) {
      return;
    }

    setPayType("");
    setCancelType("");
    navigate(
      `?page=0&startDate=${paymentDate.startDate}&endDate=${
        paymentDate.endDate
      }&startTime=${paymentTime.startTime}&endTime=${
        paymentTime.endTime
      }&month=${paymentMonth}&dateRangeType=${status}${
        searchType === "NAME" ? "&name=" : "&phone="
      }`
    );
    refetch();
    setShowingName("");
    setShowingPhone("");
    setName("");
    setPhone("");
  };

  const searchKeyword = () => {
    if (isLoading) {
      return;
    }

    setPayType("");
    setCancelType("");
    navigate(
      `?page=0&startDate=${paymentDate.startDate}&endDate=${
        paymentDate.endDate
      }&startTime=${paymentTime.startTime}&endTime=${
        paymentTime.endTime
      }&month=${paymentMonth}&dateRangeType=${status}${
        searchType === "NAME" ? `&name=${name}` : `&phone=${phone}`
      }`
    );
    refetch();
  };

  const handleSearchBtnClick = () => {
    searchKeyword();
  };

  const handleInputKeyDown = (event) => {
    if (event.key !== "Enter") {
      return;
    }

    searchKeyword();
  };

  const handleUsedBtnClick = () => {
    if (isUsedLoading) {
      return;
    }

    usedMutate({
      qrcode: qrCode,
    });
  };

  const handleOrderCancelClick = (orderNumber) => {
    setOrderNumber(orderNumber);
    setCancelShowingModal(true);
  };

  const handleOrderUsedClick = (qrcode) => {
    // console.log(qrcode);
    setQRCode(qrcode);
    setUsedShowingModal(true);
  };

  const handleOrderCancel = () => {
    if (isRemoveLoading) {
      return;
    }

    mutate(orderNumber);
  };

  const handleCanceledFilterClick = (cancelType) => {
    setCancelType(cancelType);
    navigate(
      `?page=0&startDate=${paymentDate.startDate}&endDate=${
        paymentDate.endDate
      }&startTime=${paymentTime.startTime}&endTime=${
        paymentTime.endTime
      }&month=${paymentMonth}&dateRangeType=${status}${
        searchType === "NAME" ? `&name=${name}` : `&phone=${phone}`
      }`
    );
  };

  const handlePayTypeFilterClick = (payType) => {
    setPayType(payType);
    navigate(
      `?page=0&startDate=${paymentDate.startDate}&endDate=${
        paymentDate.endDate
      }&startTime=${paymentTime.startTime}&endTime=${
        paymentTime.endTime
      }&month=${paymentMonth}&dateRangeType=${status}${
        searchType === "NAME" ? `&name=${name}` : `&phone=${phone}`
      }`
    );
  };

  const handleSmartOrderFilterClick = (_smartOrderStatus) => {
    setSmartOrderStatus(_smartOrderStatus);
    setUsedType(usedType);
    navigate(
      `?page=0&startDate=${paymentDate.startDate}&endDate=${
        paymentDate.endDate
      }&startTime=${paymentTime.startTime}&endTime=${
        paymentTime.endTime
      }&month=${paymentMonth}&dateRangeType=${status}${
        searchType === "NAME" ? `&name=${name}` : `&phone=${phone}`
      }`
    );
  };

  const handleExcelDownloadClick = () => {
    if (showingStatus === DATE_RANGE_TYPE.MONTH) {
      excelMutate({
        status: showingStatus,
        paymentDate: {
          startDate: `${showingMonth}-01`,
          endDate: `${showingMonth}-01`,
        },
        paymentTime: showingTime,
        smartOrderStatus: smartOrderStatus,
      });
      return;
    }

    excelMutate({
      status: showingStatus,
      paymentDate: showingDate,
      paymentTime: showingTime,
      smartOrderStatus: smartOrderStatus,
    });
  };

  const disabledCancelBtn = (cancelStatus, completedAt, coupons) => {
    const completedYear = new Date(completedAt).getFullYear();
    const completedMonth = new Date(completedAt).getMonth();
    const completedDate = new Date(completedAt).getDate();

    const todayYear = new Date().getFullYear();
    const todayMonth = new Date().getMonth();
    const todayDate = new Date().getDate();

    if (
      completedYear < todayYear ||
      completedMonth < todayMonth ||
      completedDate < todayDate
    ) {
      return true;
    }

    if (cancelStatus === "Y") {
      return true;
    }

    if (coupons.find((coupon) => coupon.used)) {
      return true;
    }

    return false;
  };

  const getDateByMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  const getTitleByStatus = () => {
    if (showingStatus === DATE_RANGE_TYPE.TODAY) {
      return `${todayInfoDate.totalDate} ${getKoreanDayByDayNumber(
        todayInfoDate.day
      )}요일 (${showingTime.start}~${showingTime.end})`;
    } else if (showingStatus === DATE_RANGE_TYPE.DAY) {
      return `${showingDate.startDate} (${getKoreanDayByDayNumber(
        new Date(showingDate.startDate).getDay()
      )}) ~ ${showingDate.endDate} (${getKoreanDayByDayNumber(
        new Date(showingDate.endDate).getDay()
      )})`;
    }

    const [year, month] = showingMonth.split("-");
    const date = getDateByMonth(year, month);

    const startDay = getKoreanDayByDayNumber(
      new Date(`${year}-${month}-01`).getDay()
    );
    const endDay = getKoreanDayByDayNumber(
      new Date(`${year}-${month}-${date}`).getDay()
    );

    return `${year}-${month}-01 (${startDay}) ~ ${year}-${month}-${date} (${endDay})`;
  };

  const handleInputClick = () => {
    if (!name) {
      return;
    }

    if (searchType === "NAME") {
      setShowingName("");
      setName("");
      setPage(0);
      refetch();
      navigate(
        `?page=0&startDate=${paymentDate.startDate}&endDate=${
          paymentDate.endDate
        }&startTime=${paymentTime.startTime}&endTime=${
          paymentTime.endTime
        }&month=${paymentMonth}&dateRangeType=${status}${
          searchType === "NAME" ? "&name=" : "&phone="
        }`
      );
      return;
    }

    if (!phone) {
      return;
    }

    if (searchType === "PHONE") {
      setShowingPhone("");
      setPhone("");
      setPage(0);
      refetch();
      navigate(
        `?page=0&startDate=${paymentDate.startDate}&endDate=${
          paymentDate.endDate
        }&startTime=${paymentTime.startTime}&endTime=${
          paymentTime.endTime
        }&month=${paymentMonth}&dateRangeType=${status}${
          searchType === "NAME" ? "&name=" : "&phone="
        }`
      );
      return;
    }
  };

  const getAtText = (atText) => {
    const [date, time] = atText.split(" ");
    return `${date} (${time})`;
  };
  // 주문상태 = 주문대기 여부 확인
  const isWaittingAccept = (_smartOrderStatus) => {
    let isWaited = false;
    if (_smartOrderStatus === undefined || _smartOrderStatus === null)
      return true;

    if (_smartOrderStatus?.code === SMART_ORDER_STATUS.NONE.CODE)
      isWaited = true;

    return isWaited;
  };
  // 주문상태 convert
  const convertSmartOrderStatus = (_smartOrderStatus, _cancelStatus) => {
    console.log(_smartOrderStatus);
    if (_cancelStatus === "Y") return "-";
    if (_smartOrderStatus === null) return "주문 대기";
    if (_smartOrderStatus?.code === 1) return "주문 신청";
    return _smartOrderStatus.description;
  };

  // 구매자 명 convert
  const convertUserName = (username) => {
    if (!username) {
      return "-";
    }
    const nameLen = username.length;
    if (nameLen < 3) {
      return username[0] + "*";
    }

    return username[0] + "*".repeat(nameLen - 2) + username[nameLen - 1];
  };

  const mapedPaymentInfos = paymentInfos.content.map((paymentInfo) => {
    // debugger;
    const {
      buyerName,
      cancelStatus,
      canceledAt,
      completedAt,
      orderNumber,
      payMethod,
      paymentPrice,
      pickupCompletedAt,
      productName,
      smartOrderStatus,
      used,
      coupons,
      buyerPhoneNumber,
      nonUserPhoneNumber,
    } = paymentInfo;
    const isDisabledCancelBtn = disabledCancelBtn(
      cancelStatus,
      completedAt,
      coupons
    );

    return (
      <tr key={uuid()}>
        <td data-txt="결제 일시">{getAtText(completedAt)}</td>
        <td data-txt="결제 금액">{paymentPrice.toLocaleString()}원</td>
        <td data-txt="구매 식권">{productName}</td>

        <td data-txt="구매자" style={{ whiteSpace: "pre-line" }}>
          {buyerName
            ? `${convertUserName(buyerName)}\n${
                buyerPhoneNumber
                  ? `(${buyerPhoneNumber?.slice(-5, -1)})`
                  : nonUserPhoneNumber
                  ? `(${nonUserPhoneNumber?.slice(-5, -1)})`
                  : ""
              }`
            : "-"}
        </td>
        <td data-txt="결제 수단">{payMethod}</td>
        <td data-txt="결제 상태" className={cancelStatus === "Y" ? "bgA" : ""}>
          {cancelStatus === "Y" ? "결제 취소" : "완료"}
        </td>
        <td data-txt="취소하기" className={cancelStatus === "Y" ? "bgA" : ""}>
          <button
            type="button"
            className={`btn ${
              isDisabledCancelBtn ? "btn-secondary" : "btn-light"
            }`}
            onClick={() => handleOrderCancelClick(orderNumber)}
            disabled={isDisabledCancelBtn}
          >
            취소
          </button>
        </td>
        <td data-txt="취소 일시" className={cancelStatus === "Y" ? "bgA" : ""}>
          {canceledAt ? getAtText(canceledAt) : "-"}
        </td>
        <td
          data-txt="주문 상태"
          className={
            cancelStatus === "Y"
              ? ""
              : isWaittingAccept(smartOrderStatus)
              ? "bgB"
              : ""
          }
        >
          {convertSmartOrderStatus(smartOrderStatus, cancelStatus)}
        </td>
        <td
          data-txt="픽업 완료"
          className={
            cancelStatus === "Y"
              ? ""
              : isWaittingAccept(smartOrderStatus)
              ? "bgB"
              : ""
          }
        >
          {cancelStatus === "Y" ? (
            "-"
          ) : (
            <button
              type="button"
              className={`btn ${
                smartOrderStatus?.code ===
                  SMART_ORDER_STATUS.PICK_UP_COMPLETED.CODE ||
                smartOrderStatus?.code === SMART_ORDER_STATUS.CANCELLED.CODE
                  ? "btn-secondary"
                  : "btn-light"
              }`}
              onClick={() => handleOrderUsedClick(coupons[0].qrcode)}
              disabled={
                smartOrderStatus?.code ===
                  SMART_ORDER_STATUS.PICK_UP_COMPLETED.CODE ||
                smartOrderStatus?.code === SMART_ORDER_STATUS.CANCELLED.CODE
              }
            >
              완료
            </button>
          )}
        </td>

        <td
          data-txt="픽업 완료 일시"
          className={
            cancelStatus === "Y"
              ? ""
              : isWaittingAccept(smartOrderStatus)
              ? "bgB"
              : ""
          }
        >
          {smartOrderStatus?.code === SMART_ORDER_STATUS.PICK_UP_COMPLETED.CODE
            ? getAtText(pickupCompletedAt)
            : "-"}
        </td>
      </tr>
    );
  });

  return (
    <>
      <AccountingGNB />
      <div className="demo mw-100">
        <div className="TPrdAdminPage">
          <div className="PaymentList">
            <div className="PrdAdmin-Top pb-4 px-4-5 d-flex">
              <p className="PageTit mb-0">{getTitleByStatus(status)}</p>
              <button
                type="button"
                className="btn btn-light w-auto h-auto btn-sort"
                data-bs-toggle="modal"
                data-bs-target="#Modal-04"
              >
                <img
                  className="img-fluid"
                  alt="메뉴"
                  src="https://www.flexdaycdn.net/public/images/ticket/sort_icon.png"
                />
                설정
              </button>
            </div>
            <div className="PrdAdmin-Schbox px-4-5 pb-4-5">
              <div className="inner">
                <button
                  className="btn btn-clear selectSch"
                  type="button"
                  data-bs-toggle="dropdown"
                >
                  {searchType === "NAME" ? "이름" : "연락처"}
                </button>
                <ul className="dropdown-menu sch-menu">
                  <li>
                    <input
                      className="form-check-input"
                      id="SelectSch1"
                      type="radio"
                      name="SelectSch"
                      checked={searchType === "NAME"}
                      readOnly
                    />
                    <label
                      className="RadioLabel"
                      htmlFor="SelectSch1"
                      onClick={() => setSearchType("NAME")}
                    >
                      이름
                    </label>
                  </li>
                  <li>
                    <input
                      className="form-check-input"
                      id="SelectSch2"
                      type="radio"
                      name="SelectSch"
                      checked={searchType === "PHONE"}
                      readOnly
                    />
                    <label
                      className="RadioLabel"
                      htmlFor="SelectSch2"
                      onClick={() => setSearchType("PHONE")}
                    >
                      연락처
                    </label>
                  </li>
                </ul>
                <input
                  type="text"
                  className="form-control sch-input"
                  placeholder={
                    searchType === "NAME"
                      ? "조회할 회원 이름을 입력해주세요."
                      : "조회할 회원 연락처를 입력해주세요."
                  }
                  // defaultValue={
                  //     searchType === "NAME"
                  //         ? showingName
                  //         : showingPhone
                  // }
                  value={searchType === "NAME" ? name : phone}
                  onChange={({ target }) => {
                    if (searchType === "NAME") {
                      setName(target.value);
                      return;
                    }

                    setPhone(target.value);
                  }}
                  onClick={handleInputClick}
                  onKeyDown={handleInputKeyDown}
                />
                <button
                  type="button"
                  className="btn btn-default sch-btn"
                  onClick={handleSearchBtnClick}
                >
                  조회
                </button>
              </div>
            </div>
            {/* <div className="PaymentSearchContainer">
            <div className="PaymentSearch">
                <div className="search-icon">
                <img
                    className="img-fluid"
                    alt=""
                    src="https://www.flexdaycdn.net/public/images/ticket/search(32x32).png"
                />
                </div>
                <input
                type="text"
                className="search-bar"
                placeholder="조회할 회원 이름을 입력해주세요."
                defaultValue={showingName}
                value={name}
                onChange={({ target }) => setName(target.value)}
                onClick={handleInputClick}
                onKeyDown={handleInputKeyDown}
                />
                <button
                type="button"
                className="search-btn"
                onClick={handleSearchBtnClick}
                >
                조회
                </button>
            </div>
            </div> */}
            <div className="PrdAdmin-Bottom pb-5 ps-4-5">
              <div className="PrdTbl-excelbox pc">
                {/* <DownloadTableExcel
                filename={`결제/사용 현황_${Date.now()}`}
                sheet="결제/사용 현황"
                currentTableRef={tableRef.current}
                > */}
                <button
                  type="button"
                  className="btn-clear PrdTbl-down"
                  onClick={handleExcelDownloadClick}
                  style={{ width: "auto" }}
                >
                  <span>엑셀 다운로드</span>
                  <img
                    className="img-fluid"
                    alt=""
                    src="https://www.flexdaycdn.net/public/images/ticket/down_icon.png"
                  />
                </button>
                {/* </DownloadTableExcel> */}
              </div>
              <div className="PrdTbl-excelbox tablet">
                PC에서 엑셀 파일 다운로드가 가능합니다.
              </div>
              <div className="PrdTbl-wrap">
                <table className="PrdTbl table" ref={tableRef}>
                  <thead>
                    <tr>
                      <th>결제 일시</th>
                      <th>결제 금액</th>
                      <th>구매 식권</th>
                      <th>
                        구매자
                        <br /> (연락처 끝자리)
                      </th>
                      <th
                        className="sort-th"
                        data-bs-toggle="modal"
                        data-bs-target="#Modal-01"
                      >
                        결제 수단
                        <img
                          className="img-fluid"
                          alt=""
                          src="https://www.flexdaycdn.net/public/images/ticket/arrow_down_w2.png"
                        />
                      </th>
                      <th
                        className="sort-th"
                        data-bs-toggle="modal"
                        data-bs-target="#Modal-02"
                      >
                        결제 상태
                        <img
                          className="img-fluid"
                          alt=""
                          src="https://www.flexdaycdn.net/public/images/ticket/arrow_down_w2.png"
                        />
                      </th>
                      <th>취소하기</th>
                      <th>취소 일시</th>
                      <th
                        className="sort-th"
                        data-bs-toggle="modal"
                        data-bs-target="#Modal-03"
                      >
                        주문 상태
                        <img
                          className="img-fluid"
                          alt=""
                          src="https://www.flexdaycdn.net/public/images/ticket/arrow_down_w2.png"
                        />
                      </th>
                      <th>픽업 완료</th>
                      <th>픽업 완료 일시</th>
                    </tr>
                  </thead>
                  <tbody>{mapedPaymentInfos}</tbody>
                </table>
              </div>
            </div>
            <Pagenation
              page={page}
              totalPage={paymentInfos.totalPages}
              setPage={setPage}
              status={status}
              paymentDate={paymentDate}
              setPaymentDate={setPaymentDate}
              setPaymentTime={setPaymentTime}
              setPaymentMonth={setPaymentMonth}
              paymentTime={paymentTime}
              paymentMonth={paymentMonth}
              setStatus={setStatus}
              setShowingTime={setShowingTime}
              setShowingDate={setShowingDate}
              setShowingMonth={setShowingMonth}
              setShowingStatus={setShowingStatus}
              name={name}
              setName={setName}
              showingName={showingName}
              setShowingName={setShowingName}
              showingPhone={showingPhone}
              setShowingPhone={setShowingPhone}
              searchType={searchType}
              phone={phone}
              setPhone={setPhone}
              usedType={usedType}
            />
          </div>
          <div className="modal fade" id="Modal-04" tabIndex="-1">
            <div className="modal-dialog modal-fullscreen-lg-down modal-dialog-centered modal-sorting">
              <div className="modal-content border-0">
                <div className="modal-header p-0">
                  <nav className="sub-navbar w-100">
                    <p className="navbar-title">설정</p>
                    <button
                      type="button"
                      className="btn-clear btn-close"
                      data-bs-dismiss="modal"
                      ref={modalRef}
                    >
                      <img
                        className="img-fluid"
                        alt="창닫기"
                        src="https://www.flexdaycdn.net/public/images/ticket/close.png"
                      />
                    </button>
                  </nav>
                </div>
                <div className="modal-body p-4-5">
                  <p className="sroting-tit">조회 기간</p>

                  <div className="nav sorting-pills" role="tablist">
                    <div className="nav-item" role="presentation">
                      <button
                        className="nav-link btn-clear active"
                        id="sorting-content1-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#sorting-content1"
                        type="button"
                        role="tab"
                        onClick={() => setStatus(DATE_RANGE_TYPE.TODAY)}
                      >
                        오늘
                      </button>
                    </div>
                    <div className="nav-item" role="presentation">
                      <button
                        className="nav-link btn-clear"
                        id="sorting-content2-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#sorting-content2"
                        type="button"
                        role="tab"
                        onClick={() => setStatus(DATE_RANGE_TYPE.DAY)}
                      >
                        일자별
                      </button>
                    </div>
                    <div className="nav-item" role="presentation">
                      <button
                        className="nav-link btn-clear"
                        id="sorting-content3-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#sorting-content3"
                        type="button"
                        role="tab"
                        onClick={() => setStatus(DATE_RANGE_TYPE.MONTH)}
                      >
                        월별
                      </button>
                    </div>
                  </div>

                  <div className="tab-content" id="sorting-tabContent">
                    <div
                      className="tab-pane show active"
                      id="sorting-content1"
                      role="tabpanel"
                    >
                      <div className="sorting-box">
                        <p className="sroting-tit">시간대 설정</p>
                        <PaymentTimes
                          eventDate={paymentDate}
                          setEventDate={setPaymentDate}
                          eventTime={paymentTime}
                          setEventTime={setPaymentTime}
                        />
                      </div>
                    </div>
                    <div
                      className="tab-pane"
                      id="sorting-content2"
                      role="tabpanel"
                    >
                      <div className="sorting-box">
                        <p className="sroting-tit">일자 설정</p>
                        <PaymentDay
                          eventDate={paymentDate}
                          setEventDate={setPaymentDate}
                        />
                      </div>
                    </div>
                    <div
                      className="tab-pane"
                      id="sorting-content3"
                      role="tabpanel"
                    >
                      <div className="sorting-box">
                        <YearDatepicker
                          paymentMonth={paymentMonth}
                          setPaymentMonth={setPaymentMonth}
                        />
                      </div>
                      {/* <div className="sorting-box">
                        <div className="inputbox2" onClick={() => ToggleSelected()}>
                        <input
                            type="text"
                            className="form-control InputType yearTarget active"
                            defaultValue={`${new Date().getFullYear()}-${
                            new Date().getMonth() + 1
                            }`}
                            readOnly
                        />
                        <img
                            className="img-fluid icon"
                            alt=""
                            src="https://www.flexdaycdn.net/public/images/ticket/arrow_down_b.png"
                        />
                        </div>
                        <div className="inputbox2-list SelectedList active">
                        <div className="scroll-wrap">
                            {monthOptions.reverse()}
                        </div>
                        </div>
                    </div> */}
                    </div>
                  </div>

                  <div
                    className="sorting-confirm d-flex justify-content-center PaymentDetailInfo pt-4-5"
                    // style={{ paddingTop: "5rem" }}
                  >
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={handleSubSearchBtnClick}
                    >
                      {/* {isRefetching ? (
                        <div>
                        <Spinner animation="border" />
                        </div>
                    ) : (
                        "조회"
                    )} */}
                      조회
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- 결제수단  Modal --> */}
          <div className="modal fade demoModal" id="Modal-01" tabIndex="-1">
            <div className="modal-dialog modal-sm modal-dialog-centered modal-prdfilter h-100">
              <div className="modal-content">
                <div className="modal-header px-4-5 pb-4">
                  <p className="modal-title">선택</p>
                  <button
                    type="button"
                    className="btn btn-clear"
                    data-bs-dismiss="modal"
                    ref={payModalRef}
                  >
                    <img
                      className="img-fluid"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/close.png"
                    />
                  </button>
                </div>
                <div className="modal-body px-4-5 pb-4">
                  <div className="prd-sortbox">
                    <div className="form-sort">
                      <input
                        className="form-radio-input"
                        id="sort1-radio-1"
                        type="radio"
                        name="flexRadioDefault"
                        checked={payType === PAYTYPE_STATUS.ALL}
                        onChange={() =>
                          handlePayTypeFilterClick(PAYTYPE_STATUS.ALL)
                        }
                      />
                      <label
                        className="form-radio-label"
                        htmlFor="sort1-radio-1"
                      >
                        전체
                      </label>
                    </div>
                    <div className="form-sort">
                      <input
                        className="form-radio-input"
                        id="sort1-radio-4"
                        type="radio"
                        name="flexRadioDefault"
                        checked={payType === PAYTYPE_STATUS.EASY_PAY}
                        onChange={() =>
                          handlePayTypeFilterClick(PAYTYPE_STATUS.EASY_PAY)
                        }
                      />
                      <label
                        className="form-radio-label"
                        htmlFor="sort1-radio-4"
                      >
                        간편결제
                      </label>
                    </div>
                    <div className="form-sort">
                      <input
                        className="form-radio-input"
                        id="sort1-radio-2"
                        type="radio"
                        name="flexRadioDefault"
                        checked={payType === PAYTYPE_STATUS.CARD}
                        onChange={() =>
                          handlePayTypeFilterClick(PAYTYPE_STATUS.CARD)
                        }
                      />
                      <label
                        className="form-radio-label"
                        htmlFor="sort1-radio-2"
                      >
                        카드
                      </label>
                    </div>
                    <div className="form-sort">
                      <input
                        className="form-radio-input"
                        id="sort1-radio-3"
                        type="radio"
                        name="flexRadioDefault"
                        checked={payType === PAYTYPE_STATUS.ACCOUNT_TRANSFER}
                        onChange={() =>
                          handlePayTypeFilterClick(
                            PAYTYPE_STATUS.ACCOUNT_TRANSFER
                          )
                        }
                      />
                      <label
                        className="form-radio-label"
                        htmlFor="sort1-radio-3"
                      >
                        계좌이체
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- 결제 취소 상태  Modal --> */}
          <div className="modal fade demoModal" id="Modal-02" tabIndex="-1">
            <div className="modal-dialog modal-sm modal-dialog-centered modal-prdfilter h-100">
              <div className="modal-content">
                <div className="modal-header px-4-5 pb-4">
                  <p className="modal-title">선택</p>
                  <button
                    type="button"
                    className="btn btn-clear"
                    data-bs-dismiss="modal"
                    ref={cancelModalRef}
                  >
                    <img
                      className="img-fluid"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/close.png"
                    />
                  </button>
                </div>
                <div className="modal-body px-4-5 pb-4">
                  <div className="prd-sortbox">
                    <div className="form-sort">
                      <input
                        className="form-radio-input"
                        id="sort2-radio-1"
                        type="radio"
                        name="flexRadioDefault2"
                        checked={cancelType === CANCELTYPE_STATUS.ALL}
                        onChange={() =>
                          handleCanceledFilterClick(CANCELTYPE_STATUS.ALL)
                        }
                      />
                      <label
                        className="form-radio-label"
                        htmlFor="sort2-radio-1"
                      >
                        전체
                      </label>
                    </div>
                    <div className="form-sort">
                      <input
                        className="form-radio-input"
                        id="sort2-radio-2"
                        type="radio"
                        name="flexRadioDefault2"
                        checked={cancelType === CANCELTYPE_STATUS.NO}
                        onChange={() =>
                          handleCanceledFilterClick(CANCELTYPE_STATUS.NO)
                        }
                      />
                      <label
                        className="form-radio-label"
                        htmlFor="sort2-radio-2"
                      >
                        완료
                      </label>
                    </div>
                    <div className="form-sort">
                      <input
                        className="form-radio-input"
                        id="sort2-radio-3"
                        type="radio"
                        name="flexRadioDefault2"
                        checked={cancelType === CANCELTYPE_STATUS.YES}
                        onChange={() =>
                          handleCanceledFilterClick(CANCELTYPE_STATUS.YES)
                        }
                      />
                      <label
                        className="form-radio-label"
                        htmlFor="sort2-radio-3"
                      >
                        결제 취소
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- 주문 상태  Modal --> */}
          <div className="modal fade demoModal" id="Modal-03" tabIndex="-1">
            <div className="modal-dialog modal-sm modal-dialog-centered modal-prdfilter h-100">
              <div className="modal-content">
                <div className="modal-header px-4-5 pb-4">
                  <p className="modal-title">선택</p>
                  <button
                    type="button"
                    className="btn btn-clear"
                    data-bs-dismiss="modal"
                    ref={usedModalRef}
                  >
                    <img
                      className="img-fluid"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/close.png"
                    />
                  </button>
                </div>
                <div className="modal-body px-4-5 pb-4">
                  <div className="prd-sortbox">
                    {Object.keys(SMART_ORDER_STATUS).map((status, index) =>
                      SMART_ORDER_STATUS[status].ISVISIBLE === false ? null : (
                        <div className="form-sort" key={`form-sort-${index}`}>
                          <input
                            className="form-radio-input"
                            id={"sort3-radio-" + index}
                            type="radio"
                            name="flexRadioDefault3"
                            checked={
                              smartOrderStatus ===
                              SMART_ORDER_STATUS[status].API
                              // usedType === USEDTYPE_STATUS.ALL
                            }
                            onChange={() =>
                              handleSmartOrderFilterClick(
                                SMART_ORDER_STATUS[status].API
                              )
                            }
                          />
                          <label
                            className="form-radio-label"
                            htmlFor={"sort3-radio-" + index}
                          >
                            {SMART_ORDER_STATUS[status].DESCRIPTION}
                          </label>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 취소 확인 모달 */}
          <Modal
            show={isCancelShowingModal}
            onHide={() => setCancelShowingModal(false)}
            keyboard={false}
            size="sm"
            centered
            className="demoModal modal-prdAdd text-center"
          >
            <Modal.Header>
              <Modal.Title>식권 결제 취소</Modal.Title>
            </Modal.Header>
            <Modal.Body>해당 식권을 취소 하시겠습니까?</Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
              {isRemoveLoading ? (
                <div>
                  <Spinner animation="border" />
                </div>
              ) : (
                <>
                  <Button
                    variant="modal-secondary"
                    className="btn-clear"
                    onClick={() => setCancelShowingModal(false)}
                  >
                    취소
                  </Button>
                  <Button
                    variant="modal-primary"
                    className="btn-clear"
                    onClick={handleOrderCancel}
                  >
                    확인
                  </Button>
                </>
              )}
            </Modal.Footer>
          </Modal>
          <Modal
            show={isUsedShowingModal}
            onHide={() => setUsedShowingModal(false)}
            keyboard={false}
            size="sm"
            centered
            className="demoModal modal-prdAdd text-center"
          >
            <Modal.Header>
              <Modal.Title>식권 사용처리</Modal.Title>
            </Modal.Header>
            <Modal.Body>해당 식권을 사용처리 하시겠습니까?</Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
              {isUsedLoading ? (
                <div>
                  <Spinner animation="border" />
                </div>
              ) : (
                <>
                  <Button
                    variant="modal-secondary"
                    className="btn-clear"
                    onClick={() => setUsedShowingModal(false)}
                  >
                    취소
                  </Button>
                  <Button
                    variant="modal-primary"
                    className="btn-clear"
                    onClick={handleUsedBtnClick}
                  >
                    확인
                  </Button>
                </>
              )}
            </Modal.Footer>
          </Modal>
          {isExcelLoading && (
            <LoadingPortal>
              <LoadingModal type="black" />
            </LoadingPortal>
          )}
        </div>
      </div>
    </>
  );
}
