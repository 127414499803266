import React from "react";
import LoadingPortal from "../../../portals/LoadingPortal";
import LoadingModal from "../../loadingmodal/LoadingModal";
import useCalculateDetail from "../../../hooks/paymentinfo/useCalculateDetail";
import {
  getKoreanDayByDayNumber,
  getKoreanYearMonthDay,
} from "../../../function/common";

export default function CalculateDetailInfo({
  settlementId,
  setIsShowingPopup,
}) {
  const { data } = useCalculateDetail(settlementId, setIsShowingPopup);

  if (!data) {
    return (
      <LoadingPortal>
        <LoadingModal />
      </LoadingPortal>
    );
  }

  const {
    startDate,
    endDate,
    settlePrice,
    settleStatus,
    scheduledSettledAt,
    storeSalesPrice,
    fee,
    account,
  } = data;

  const startDay = getKoreanDayByDayNumber(new Date(startDate).getDay());
  const dotStartDate = startDate.split("-").join(". ");
  const endDay = getKoreanDayByDayNumber(new Date(endDate).getDay());
  const dotEndDate = endDate.split("-").join(". ");

  return (
    <div className="demo">
      <div
        className={`TSettleDetailPage ${settleStatus.code === 2 && "done"}`}
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          transform: "translateX(-50%)",
          left: "50%",
          background: "#fff",
        }}
      >
        {/* <!-- 정산완료 : className "done" 추가--> */}
        <div
          className="container-fluid g-0"
          style={{ maxWidth: "800px", height: "100vh" }}
        >
          <nav className="sub-navbar">
            <p className="navbar-title">정산 상세</p>
            <button
              type="button"
              className="btn-clear btn-close"
              onClick={() => {
                setIsShowingPopup(false);
              }}
            >
              <img
                className="img-fluid"
                alt="뒤로가기"
                src="https://www.flexdaycdn.net/public/images/ticket/close5.png"
              />
            </button>
          </nav>
          <div className="DetailBoxContainer">
            <div className="DetailBox pt-4-5 px-4-5">
              <div className="Detail-1 pb-4">
                <p className="week align-items-center d-flex mb-0">
                  <img
                    className="img-fluid calendar"
                    alt=""
                    src="https://www.flexdaycdn.net/public/images/ticket/calendar.png"
                  />
                  <span>이번 주</span>
                </p>
                <p className="period mb-0">
                  {dotStartDate}({startDay}) ~ <br className="mob320" />
                  {dotEndDate}({endDay})
                </p>
              </div>
              <div className="Detail-2 py-4">
                <p className="mb-0 state text-end">
                  <span className="px-2 py-1">{settleStatus.description}</span>
                </p>
                <p className="txt mb-0">총 입금 금액</p>
                <p className="price mb-0">
                  <span>{settlePrice?.toLocaleString()}</span> 원
                </p>
              </div>
              <div className="Detail-3 py-4-5">
                <div className="align-items-center d-flex justify-content-between mb-2">
                  <div className="align-items-center d-flex tit">
                    <img
                      className="img-fluid icon"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/text.png"
                    />
                    총 판매액
                  </div>
                  <div className="desc">
                    <span>{storeSalesPrice?.toLocaleString()}</span> 원
                  </div>
                </div>
                <div className="align-items-center d-flex justify-content-between mb-2">
                  <div className="align-items-center d-flex tit">
                    <img
                      className="img-fluid icon"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/text.png"
                    />
                    수수료
                  </div>
                  <div className="desc">
                    <span>-{fee?.toLocaleString()}</span> 원
                  </div>
                </div>
              </div>
            </div>
            <div className="DetailBox2 pt-4-5 px-4-5">
              <div className="Detail-4">
                <div className="align-items-center d-flex justify-content-between mb-2">
                  <div className="tit">
                    {settleStatus.code === 2 ? "정산 완료일" : "정산 예정일"}
                  </div>
                  <div className="desc date">
                    {getKoreanYearMonthDay(scheduledSettledAt)}
                  </div>
                </div>
                <div className="align-items-start d-flex justify-content-between mt-4">
                  <div className="tit">
                    {settleStatus.code === 2
                      ? "정산 완료 계좌"
                      : "정산 예정 계좌"}
                  </div>
                  <div className="desc text-end">
                    {account?.bank.korName ? account.bank.korName : ""} <br />
                    {account?.accountNumber ? account.accountNumber : ""} <br />
                    {account?.accountOwner ? account.accountOwner : ""}
                  </div>
                </div>
              </div>
            </div>

            <div className="DetailInfotxt py-4-5 text-center">
              정산 관련 문의는
              <br />
              고객센터 (051-621-0203)를 이용해주세요.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
